@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes blink {
  0% {
    transform: scale(1);
    opacity: .5;
  }

  50% {
    transform: scale(1);
    opacity: .5;
  }
  

  75% {
    transform: scale(1.3);
    opacity: 1
  }

  100% {
    transform: scale(1);
    opacity: .5;
  }
}

.plus-blink {
  animation: blink 1s infinite;
}

* {
  user-select: none;
  font-family: Montserrat;
}

.button-shadow {
  box-shadow: 2px 2px 0 #FC2BEE
}

.keen-slider__slide:hover
  .share-spoilers{
    bottom: 0;
}
.share-spoilers{
  transition: bottom .3s;
}